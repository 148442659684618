import {Layout, Menu, Space, Typography, Modal, Button, Dropdown,
    message, Form, Input, Table, Pagination, Tooltip, Row, Col} from 'antd';
import { CaretDownOutlined, SearchOutlined, ExportOutlined } from '@ant-design/icons';
import React, {useState, useEffect, useContext, useMemo, memo} from 'react';
import './rrk.css';
import Breadcrumb from "antd/es/breadcrumb";

import OlMap from 'ol/Map';
import OlView from 'ol/View';
import OlLayerTile from 'ol/layer/Tile';
import {MapComponent} from '@terrestris/react-geo';
import XYZ from "ol/source/XYZ";
import OlSourceTileWMS from "ol/source/TileWMS";
import axios from 'axios';
import WorkspaceContext from '../WorkspaceContext';
import download from './download';
import WMSCapabilities from 'ol/format/WMSCapabilities.js';
import {boundingExtent} from 'ol/extent'
import {transformExtent, get, fromLonLat} from 'ol/proj.js';
const fetch = require('sync-fetch');




const base_url = process.env.REACT_APP_API_URL


async function getCollectionList (access_token) {
    let url = `${base_url}/Dataset/Dataset_Collections/counts`
    console.log("getCollectionList url")
    console.log(url)
    let collections = await fetch(url, 
        {
            method: "GET"
        }
    );

    let collection_list = await collections.json()

    return collection_list
}

const sortTaxa = (taxa_list) => {

    taxa_list.forEach(taxa => {
        let children = taxa['children']
        console.log("taxa child")
        console.log(taxa)
        console.log(children)
        if (children && children.length > 0){
            let initial_sorted = children.sort(function (a, b) {
                return a.label.localeCompare(b.label);
            });

            initial_sorted.forEach(child => {
                if (child['children'] && child['children'].length > 0){
                    let sorted_child = child['children'].sort(function (a, b) {
                                            return a.label.localeCompare(b.label);
                                        });
                    
                    child['children'] = sorted_child
                }
            })

            taxa['children'] = initial_sorted
        }
    })

    return taxa_list
} 

const setTaxonomyFromCollectionId = async(collection_id, setCollection, setTaxonomyList) => {
    let collection = await fetch(`${base_url}/DatasetCollection/${collection_id}`)
    let collection_data = collection.json()
    console.log("getTaxonomyFromCollectionId collection")
    console.log(collection_data)
    setCollection(collection_data)

    let collection_taxonomies = await fetch(`${base_url}/DatasetCollection/${collection_id}/taxonomy`).json()

    console.log("collection_taxonomies")
    console.log(collection_taxonomies)

    if(collection_taxonomies.length > 0){
        let all_taxa = []
            
        await collection_taxonomies.forEach(async e => {
            let taxonomy_list = await fetch(`${base_url}/DatasetCollection/${collection_id}/taxonomy/${e.taxonomy_id}/hierarchy`).json()
            all_taxa.push(taxonomy_list)
        })

        console.log("all_taxa asdfadf")
        console.log(all_taxa)

        let sorted_taxa = sortTaxa(all_taxa[0])

        console.log("sorted_taxa")
        console.log(sorted_taxa)

        await setTaxonomyList([sorted_taxa])
    } else {
        let datasets = await fetch(`${base_url}/DatasetCollection/${collection_id}/Dataset`).json()

        // console.log('datasets here for non taxa')
        // console.log(datasets)

        let all_taxa = [datasets.map(e => {
            return {
                key: e.dataset_id,
                label: e.name
            }
        })]

        console.log("all_taxa aaaaaaa")
        console.log(all_taxa)
       
        await setTaxonomyList(all_taxa)
    }
}

const getSelectedKeys = (split_path) => {

    let selectedKeys = []
    let prevItem = ''
    split_path.forEach(e => {
        let key = prevItem + '/' + e
        selectedKeys.push(key)
        prevItem = key
    })

    return selectedKeys
}



const find_dataset_taxa = async (taxonomy_item_id, taxonomy_list) => {
    console.log("taxonomy_list in set selected")
    // console.log(taxonomy_list)
    // console.log(dataset)

    // let taxonomy_item_id = dataset.taxonomy_items[0].taxonomy_item_id
    let taxa_key = []
    let found = false

    console.log("taxonomy_item_id")
    console.log(taxonomy_item_id)
    console.log("taxonomy_list")
    console.log(taxonomy_list)

    const find_taxa = async(taxa_list) => {
        taxa_list.forEach((item, i) => {
            if(found === false){
                console.log('taxonomy_item_id')
                console.log(taxonomy_item_id)
                //is taxonomy_item_id within the item's id and the next one? if so go into that tree
                if(item.taxonomy_item_id !== undefined 
                    && item.taxonomy_item_id <= taxonomy_item_id 
                    && (i === (taxa_list.length-1) 
                        || (taxa_list[i+1].taxonomy_item_id !== undefined 
                            && taxa_list[i+1].taxonomy_item_id >= taxonomy_item_id)))
                {
                    taxa_key.push(item.taxonomy_item_name)

                    console.log(item.taxonomy_item_name)
                    console.log(item.taxonomy_item_id)
                    console.log(item.taxonomy_item_id === taxonomy_item_id)
                    
                    if (item.taxonomy_item_id === taxonomy_item_id) {
                        found = true                    
                    } else if(item.children !== undefined && item.children.length > 0) {
                        find_taxa(item.children)
                    }   
                }
            }
        })         
    }
    
    await find_taxa(taxonomy_list)

    return taxa_key
}

const setDatasetAndDict = async(dataset_id, setDataset, setDataDict) => {
    let dataset = await fetch(`${base_url}/Dataset/${dataset_id}`).json()
    console.log('set dataset dataset')
    console.log(dataset)
    setDataset(dataset)

    console.log("dataset_id")
    console.log(dataset_id)

    if(dataset !== undefined){
        // let data_dict = await fetch(`${base_url}/Dataset/${dataset_id}/DatasetMetadata`).json()
        console.log(`${base_url}/DictionarySection/`)
        let allDataDics = await fetch(`${base_url}/DictionarySection/`).json()
        console.log("allDataDics")
        console.log(allDataDics)
        if(allDataDics.length === 1){
            let dictionary_section_id = allDataDics[0].dictionary_section_id
            let data_dict = await fetch(`${base_url}/DictionarySection/${dictionary_section_id}/DictionaryItem`).json()
            console.log('data_dict')
            setDataDict(data_dict)
        }
    }
}

async function getSetCollectionList(access_token, setCollectionList) {
    let collection = await getCollectionList(access_token)
    await setCollectionList(collection)
}


const getExtent = async (layerInfo) => {
    console.log(layerInfo)
    if(layerInfo.service_type === "ArcGIS"){
        const parser = new WMSCapabilities();
        let url = `${layerInfo.service_url}/query?where=1%3D1&returnExtentOnly=true&f=pjson`
        console.log("url")
        console.log(url)
        let capabilities = await fetch(url)
        let capabilities_text = capabilities.text()

        console.log("capabilities_text")
        console.log(capabilities_text)
        
        let response = JSON.parse(capabilities_text);        
        console.log(response)
    
        if(response && response.extent !== undefined) {
            return [response.xmin, response.ymin, response.xmax, response.ymax]
        } else {
            return []
        }
    } else {
        const parser = new WMSCapabilities();
        let url = `${layerInfo.service_url}?SERVICE=WMS&version=1.3.0&REQUEST=GetCapabilities`
        console.log("url")
        console.log(url)
        let capabilities = await fetch(url)
        let capabilities_text = capabilities.text()
        
        let response_test = parser.read(capabilities_text);        
        console.log("response_test")
        console.log(response_test)
    
        let cap_layer = response_test.Capability.Layer.Layer
            .filter(e => e.Title.toLowerCase() === layerInfo.layer_name.split(':')[1].toLowerCase())
        
        console.log(cap_layer)
    
        if(cap_layer && cap_layer.length > 0) {
            return cap_layer[0].EX_GeographicBoundingBox
        } else {
            return []
        }
    }
}




export {getCollectionList, setTaxonomyFromCollectionId, find_dataset_taxa, getSelectedKeys, setDatasetAndDict, getSetCollectionList, getExtent};