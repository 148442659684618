import {Modal} from "antd";

const download2 = (layer, filename, setLoading) => {
    let xmlBodyStr = `<?xml version="1.0" encoding="UTF-8"?><wps:Execute version="1.0.0" service="WPS" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.opengis.net/wps/1.0.0" xmlns:wfs="http://www.opengis.net/wfs" xmlns:wps="http://www.opengis.net/wps/1.0.0" xmlns:ows="http://www.opengis.net/ows/1.1" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns:wcs="http://www.opengis.net/wcs/1.1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd">
 <ows:Identifier>gs:Download</ows:Identifier>
 <wps:DataInputs>
  <wps:Input>
   <ows:Identifier>layerName</ows:Identifier>
   <wps:Data>
    <wps:LiteralData>${layer}</wps:LiteralData>
   </wps:Data>
  </wps:Input>
  <wps:Input>
   <ows:Identifier>outputFormat</ows:Identifier>
   <wps:Data>
    <wps:LiteralData>image/tiff</wps:LiteralData>
   </wps:Data>
  </wps:Input>
 </wps:DataInputs>
 <wps:ResponseForm>
  <wps:RawDataOutput mimeType="application/zip">
   <ows:Identifier>result</ows:Identifier>
  </wps:RawDataOutput>
 </wps:ResponseForm>
</wps:Execute>`;

    fetch('https://sparcal.sdsc.edu/geoserver/wps', {
        method: 'POST',
        headers: {
            'Content-Type': 'text/plain',
        },
        body: xmlBodyStr
    }).then((transfer) => {
        return transfer.blob();
    }).then((bytes) => {
        let elm = document.createElement('a');
        elm.href = URL.createObjectURL(bytes);
        elm.download = `${filename}.geotiff`
        elm.setAttribute('download', `${filename}.zip`);
        elm.click();
        setLoading(false);
    }).catch((error) => {
        setLoading(false);
        Modal.error({
            title: 'Error',
            content: error.message
        });
    })


}

export default download2;


