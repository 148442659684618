import {Layout, Menu, Space, Typography, Modal, Button, Dropdown,
    message, Form, Input, Table, Pagination, Tooltip, Row, Col} from 'antd';
import { CaretDownOutlined, SearchOutlined, ExportOutlined } from '@ant-design/icons';
import React, {useState, useEffect, useContext, useMemo, useCallback} from 'react';
import './rrk.css';
import Breadcrumb from "antd/es/breadcrumb";

import OlMap from 'ol/Map';
import OlView from 'ol/View';
import OlLayerTile from 'ol/layer/Tile';
import {MapComponent} from '@terrestris/react-geo';
import XYZ from "ol/source/XYZ";
import OlSourceTileWMS from "ol/source/TileWMS";
import axios from 'axios';
import WorkspaceContext from '../WorkspaceContext';
import download from './download';
import download_shape from './download_shape'
import WMSCapabilities from 'ol/format/WMSCapabilities.js';
import {boundingExtent} from 'ol/extent'
import {transformExtent, get, fromLonLat} from 'ol/proj.js';
// import {getCollectionList} from './catalog_func.js';

import {getCollectionList, setTaxonomyFromCollectionId, find_dataset_taxa,
     getSelectedKeys, setDatasetAndDict, getSetCollectionList, getExtent} from './catalog_func.js';
import userEvent from '@testing-library/user-event';

const {Header, Content, Sider} = Layout;
const { Search } = Input;
const {Text, Title} = Typography;
const fetch = require('sync-fetch');

const DataCatalog = (props) => {

//    const [hierarchy, setHierarchy] = useState([]);
   const [category, setCategory] = useState([]);
   const [region, setRegion] = useState(props.regionReq);
   const {selectedDatasets, setSelectedDatasets, setRegionReq, setDefaultKeys, 
       setSelectedKeys, defaultKeys, selectedKeys} = useContext(WorkspaceContext);
   const [categoryDetails, setCategoryDetails] = useState(null);
   const [loading, setLoading] = useState(false);
   // const [defaultKeys, setDefaultKeys] = useState([])
   // const [selectedKeys, setSelectedKeys] = useState([])
   const [openSearch, setOpenSearch] = useState(false)
   const [searchResult, setSearchResult] = useState([])

   const [collection_list, setCollectionList] = useState([])
   const [taxonomy_list, setTaxonomyList] = useState([])
   const [collection_id, setCollectionId] = useState(null)
   const [collection, setCollection] = useState({})
   const [hierarchy, setHierarchy] = useState([])
   const [dataset_id, setDatasetId] = useState(null)
   const [dataset, setDataset] = useState(null)
   const [data_dict, setDataDict] = useState([])

   const[base_url, ] = useState(process.env.REACT_APP_API_URL)

   const [searchCols, setSearchCols] = useState([
       {
           title: 'Region',
           dataIndex: ['dataset_collections', '0', 'name'],
           key: 1 ,
           render: (text, record) => 
           {
               let region = text.replace('Regional Resource Kits', "")
               return (
                   <div>
                       {region}
                   </div>
               ) 
           }
       },
       {
         title: 'Title',
         dataIndex: 'name',
         key: 2       
       },
       {
         title: 'Description',
         dataIndex: 'dataset_metadata',
         key: 3,
         render: (text, record) => 
         {
            console.log('descriptions go here')
            console.log(text)            
            console.log(record)  
           let description = text.filter(e => e.name == 'metric_definition_and_relevance')    
           
           if (description.length > 0){
                description = description[0].text_value
           }

           let ellipsis = description.length > 251 ? description.slice(0, 250) + '...' : description
           return (
               <div>
                    <span size="small">{ellipsis}</span>
               </div>
           )  
         }      
       },
       {
           title: 'Action',
           dataIndex: 'actions',
           key: 4,
           render: (text, record) => {
               return <div>
                   <Tooltip title="Navigate to Data Source" placement='top'>
                       <Button onClick={()=> navToDataset(record)} type="primary" size="small" icon={<ExportOutlined />}></Button>
                   </Tooltip>
               </div>
           }
       }
     ])



    useEffect(() => {
        async function setCollections(access_token) {
            let collection = await getCollectionList(access_token)
            await setCollectionList(collection)
        }
        
        setCollections(props.access_token).catch(console.error);

    }, []);

    const onClick = (e) => {
       setDefaultKeys(e.keyPath)
       setSelectedKeys(e.keyPath)
       setDatasetId(Number(e.key))
    };


    useEffect(() => {

        console.log("collection_id here")
        console.log(collection_id)
        if(collection_id !== null) { 

            setTaxonomyFromCollectionId(collection_id, setCollection, setTaxonomyList)

            // console.log("dataset")
            // console.log(dataset)
            // console.log("dataset_id")
            // console.log(dataset_id)

            // if(dataset_id != null && dataset !== undefined && dataset !== null){
            //     if(dataset.taxonomy_items.length > 0){
            //         let taxa_key = find_dataset_taxa(dataset.taxonomy_items[0].taxonomy_item_id, 
            //             taxonomy_list[0])

            //         if(taxa_key.length > 0) {
            //             console.log("taxa_key here")
            //             console.log(taxa_key)
            //             let split_path = taxa_key.split('/').splice(1)
            //             let selected_keys = getSelectedKeys(split_path)
            //             console.log("selected_keys")
            //             console.log(selected_keys)
            //             onClick({'key' : dataset_id, 'keyPath' : selected_keys})
            //         }
            //     } else{
            //         console.log('herererer')
            //         onClick({'key' : dataset_id, 'keyPath' : dataset.name})
            //     }
            // }
        }

    }, [collection_id])


    useEffect(() => {
        console.log("dataset_id changed")
        console.log(dataset_id)

        if(dataset_id){
            setDatasetAndDict(dataset_id, setDataset, setDataDict)
            getSetCollectionList(props.access_token, setCollectionList);
        }
    }, [dataset_id])


    const getDatasetTaxa = async (taxonomy_item_id, taxonomy_list) => {
        const key = await find_dataset_taxa(taxonomy_item_id, taxonomy_list)
        return key
    }

    useEffect(() => {

        if(dataset !== undefined && dataset !== null){
            if(dataset.taxonomy_items.length > 0 && taxonomy_list.length > 0 ){
                const getSetTaxa = async () => {
                    const taxa_key = await getDatasetTaxa(dataset.taxonomy_items[0].taxonomy_item_id, taxonomy_list[0])

                    if(taxa_key.length > 0) {
                        console.log('here maybe please')
                        // let split_path = taxa_key.split('/').splice(1)
                        let selected_keys = getSelectedKeys(taxa_key)
                        onClick({'key' : dataset_id, 'keyPath' : selected_keys})
                    }
                }

                getSetTaxa()
            } else{
                onClick({'key' : dataset_id, 'keyPath' : dataset.name})
            }
        }
    }, [taxonomy_list])

    const backgroundLayer = new OlLayerTile({
       source: new XYZ({
           crossOrigin: "anonymous",
           url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
       })
   });

   var layers = [backgroundLayer];

   console.log("dataset")
   console.log(dataset)

   let projection =  dataset && dataset.projection ? dataset.projection : 'EPSG:4326'

//    console.log("projection")
//    console.log(projection)

//    important: this prevents re-rendering the map
   const map = useMemo(() => {
       if (category == null) return null;
       const backgroundLayer = new OlLayerTile({
           source: new XYZ({
               crossOrigin: "anonymous",
               url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
           })
       });
       return new OlMap({
           layers: [backgroundLayer],
           view: new OlView({
               projection: projection,
               center: [-119, 37.5], //center-ish of california
               zoom: 5.7
           }),
       })
       
   }, [region, category]);

   var layerConfig = dataset;
   var legend_url = null;
   var dataLayer = null;
   if (layerConfig && layerConfig.gis_services.length > 0) {
       console.log('does this get called again?')
        console.log(layerConfig.gis_services)
        let layerInfo = layerConfig.gis_services[0]

        console.log("layerInfo")
        console.log(layerInfo)

        dataLayer = new OlLayerTile({
            visible: true,
            opacity: 0.7,
            type: 'WMS',
            layer_name: layerInfo.layer_name,
            service_url: layerInfo.service_url,
            source: new OlSourceTileWMS({
                url: layerInfo.service_url,
                crossOrigin: "anonymous",
                params: {
                    'LAYERS': layerInfo.layer_name,
                    'STYLES': undefined,
                    'TILED': true
                },
                serverType: 'geoserver',
            })
        });

        console.log("dataLayer")
        console.log(dataLayer)

        layers.push(dataLayer);

        async function setExtent(layerInfo, map) {
            let extent_vals = await getExtent(layerInfo)
            console.log("extent_vals")
            console.log(extent_vals)
            if(extent_vals.length == 0){
                extent_vals =  [-124.50616867328175, 32.42378080292498, -113.49845090342284, 42.06575122482847]
            }
            map.getView().fit(extent_vals , map.getSize());
        }

        setExtent(layerInfo, map)

        map.setLayers(layers);
   }

   if (dataLayer && dataLayer.getSource) {
       legend_url = dataLayer.getSource().getLegendUrl(map.getView().getResolution());
       legend_url += 'width=5&height=10&TRANSPARENT=true&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontSize:10';
       setTimeout(() => dataLayer.getSource().refresh(), 1000);
   }

   const inWorkspace = () => {
       if (!layerConfig) return false;
       for (let i = 0; i < selectedDatasets.length; i++) {
           if (selectedDatasets[i].gis_services !== undefined && selectedDatasets[i].gis_services.length > 0 
            && selectedDatasets[i].data_type !== undefined && selectedDatasets[i].file_path !== undefined ) {
               if (selectedDatasets[i].gis_services[0].layer_name === layerConfig.gis_services[0].layer_name 
                && selectedDatasets[i].gis_services[0].service_url === layerConfig.gis_services[0].service_url) {
                   return true;
               }
           }
       }

       return false;
   }

   const isNotShapeFile = (dataset) => {
        return dataset.data_type != 'shapefile'
   }

   const addToWorkspace = () => {
       if (!inWorkspace()) {
           let newSelectedDatasets = [...selectedDatasets];
           newSelectedDatasets.push(layerConfig);
           setSelectedDatasets(newSelectedDatasets);
       }
   }

   const removeFromWorkspace = () => {
       for (let i = 0; i < selectedDatasets.length; i++) {
           if (selectedDatasets[i].gis_services[0].layer_name === dataset.gis_services[0].layer_name 
                && selectedDatasets[i].gis_services[0].service_url === dataset.gis_services[0].service_url){
               let newSelectedDatasets = [...selectedDatasets];
               newSelectedDatasets.splice(i, 1);
               setSelectedDatasets(newSelectedDatasets);
               break;
           }
       }
   }

   console.log('base url here')
   console.log(base_url)

   const showModal = () => {
       setOpenSearch(true)
     };

   const closeModal = () => {
       setOpenSearch(false)
   };

   const clearSearch = () => {
       setSearchResult([])
   }

   const retrieveSearch = async (search_terms) => {
       console.log("retrieveSearch")
       console.log(search_terms)

       const breakpoint = /\W/
       const terms = search_terms.split(breakpoint).filter(str => str !== '').join(' & ');

       let base_url_dev = 'http://127.0.0.1:8000'

       const results = await fetch(base_url_dev + '/Dataset/?search_terms=' + encodeURIComponent(terms) + '&skip=0&limit=100&order_by=dataset_id&ascending=true',
           {
               method: "GET",
               headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json',
               },
           })
       

       let search_result = results.json()

       console.log("search_result")
       console.log(search_result)

       let its_single_row = search_result.filter(e => e.data_type === 'vector')[0]

       if (its_single_row){
           its_single_row.name = its_single_row.dataset_collections ? its_single_row.dataset_collections[0].name : ''
           search_result = search_result.filter(e => e.data_type !== 'vector')
           search_result.push(its_single_row)
       }

       let unique_results = []

       search_result.forEach(res => {
           let is_unique = unique_results.filter(e => e.file_path === res.file_path && e.dataset_collections[0].name === res.dataset_collections[0].name).length == 0
           if(is_unique){
               unique_results.push(res)
           }
       })

       console.log(unique_results)

       setSearchResult(unique_results)
   }

//    function searchJSON(obj, key, path) {
//        for (let k in obj) {
//            let vals = obj[k]
//            if(vals.label === key){
//                path.push(vals.key)
//            } else if(vals.children !== undefined && vals.children.length !== 0) {
//                searchJSON(vals.children, key, path)
//                if(path.length !== 0){
//                    break;
//                }
//            }
//        }

//        return path
//    }

//    const getSelectedKeys = (split_path) => {

//        let selectedKeys = []
//        let prevItem = ''
//        split_path.forEach(e => {
//            let key = prevItem + '/' + e
//            selectedKeys.push(key)
//            prevItem = key
//        })

//        return selectedKeys
//    }

   const navToDataset = async (record) => {
       closeModal()

       console.log("record")
       console.log(record)

       await setCollectionId(record.dataset_collections[0].data_collection_id)
       await setDatasetId(record.dataset_id)
   }
//        let taxonomy = await getTaxonomyFromCollectionId(record.dataset_collections[0].data_collection_id)

//        console.log("taxonomy")
//        console.log(taxonomy)

//        if(record.data_type === 'raster'){


//             console.log("taxonomy_list in nav")
//             console.log(taxonomy_list)


//            let region = record.dataset_collections[0].name.replace(' Regional Resource Kits', "")
//            let url = `rrk_metadata/${region}/index.json`

//            let title = record.name

//         //    let hierarchy = fetch(url).json();
//         //    let path = searchJSON(hierarchy, title, [])[0]

//         //    let split_path = path.split('/').splice(1)
//         //    let selected_keys = getSelectedKeys(split_path)

//         //    console.log(selected_keys)
       
//         //    await setRegion(region)
//         //    await setDefaultKeys(selected_keys)
//         //    await setSelectedKeys(selected_keys)
//         //    await setCategory(split_path);
//         // //    await setCategoryDetails(getCategoryDetails(split_path));

//         //    onClick({'key' : path, 'keyPath' : selected_keys})
//        } else {
//            props.setDisplay("map");

//            let itsSelected = selectedDatasets.filter(e => e.folder === '/Interagency Tracking System')

//            let checkedDatasets = {...props.checkedDatasets}

//            itsSelected.forEach(e => {
//                checkedDatasets['https://sparcal.sdsc.edu/geoserver/ITS/wms/' + e.mapping[0].layer ] = true
//            })

//            props.setCheckedDatasets(checkedDatasets)
//        }
//    }

   const onOpenChange = (keys) => {

       let rootSubmenuKeys = hierarchy.map(e => {
           return e.key
       })

       const latestOpenKey = keys.find((key) => defaultKeys.indexOf(key) === -1);
       if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setDefaultKeys(keys);
       } else {
           setDefaultKeys(latestOpenKey ? [latestOpenKey] : []);
           setSelectedKeys(keys)
       }
   };

   // prevent loading spinner
   setTimeout(() => {
       const element = document.getElementById("map");
       element.className = '';
   }, 100);

   const selectCollections = ({ key }) => {
       setCollectionId(key);
       setDefaultKeys([])
       setSelectedKeys([])
       setDataset(null)
   };

   const colorBgContainer = "white";

   console.log("collection_list")
   console.log(collection_list)
   console.log("taxonomy_list")
   console.log(taxonomy_list)


   console.log("dataset")
   console.log(dataset)
   console.log("collection")
   console.log(collection)


   let metadata = dataset ? dataset.dataset_metadata.sort((a, b) => a.name.localeCompare(b.name)) : {}

   let datasource_dropdown = collection_list.map(e => {
        return {
            'key' : e.data_collection_id,
            'label' : e.data_collection_name
        }
   })

   let column_dict = {
    'data_resolution' : 'Resolution',
    "projection" : "Projection",
    'data_units' : 'Units',
    'data_vintage' : 'Data Vintage',
    'leading_paragraphs' : 'Paragraphs',
    'max_value' : 'Max Value',
    'min_value' : 'Min Value',
    'tier' : "RRK Tier",
    'name' : "Name",
    'data_type' : 'Data Type',
    'file_path' : 'File Path',
    'file_type' : 'File Type',
    'report_title_template' : 'Report Title',
    'last_update' : 'Updated Date',
    "updated_data_source" : 'Data Source',
    "creation_method" : 'Creation Method',
    "metric_definition_and_relevance" : 'Metric Definition',
    "report_description_template" : 'Description',
    "description" : "Description"
   }

   return (           
       <div id="data_browse"
            style={{
                display: props.display === 'data_catalog' ? 'flex' : 'none',
                flexDirection: 'column',
                width: '100%',
                height: 'calc(100vh - 40px)'
            }}>
           <Layout>
               <Header
                   style={{
                       display: 'flex',
                       alignItems: 'center',
                       backgroundColor: '#EEEDE3',
                       height: 40,
                       marginLeft: -27,
                       color: 'black',
                       fontWeight: 'bold',
                       marginBottom: '5px'
                   }}
               >
                   Data Sources
                   <span style={{width: 20, color:'lightgray'}}></span>
                   {/* <Button type="primary"
                           style={{
                               backgroundColor: '#D11E00',
                               borderColor: "white"
                           }}
                           onClick={() => onClick({'key' : '/Fire Dynamics/Functional Fire/Current Fire Return Interval Departure, Since 1970'})}>
                       ahhhhhh
                   </Button> */}

                   <Dropdown menu={{
                               items: datasource_dropdown,
                               onClick: selectCollections,
                             }}
                             placement={"bottomLeft"}
                             style={{ color: 'black' }}>
                            <Button style={{borderRadius: 5}}>
                                <Space>
                                <span>
                                    {collection_list.length > 0 && collection_id !== null
                                    ? collection_list.filter(e => e.data_collection_id === Number(collection_id))[0].data_collection_name
                                        : '- SELECT -'}
                                </span>
                                <CaretDownOutlined style={{color: 'gray', paddingLeft: 5}}/>
                                </Space>
                            </Button>
                       
                   </Dropdown>

                   <Button style={{ marginLeft: 'auto', color:'black' }} icon={<SearchOutlined />} ghost 
                       onClick={showModal}>
                       Search
                   </Button>

                   <Modal 
                       title="Search Data Sources"
                       open={openSearch}
                       onCancel={closeModal}
                       footer={null}
                       style={{
                           minWidth:'1000px',
                           zIndex: -1
                       }}
                       >                            
                       <div>
                           <Form>
                               <Form.Item 
                                   label="Search RRK"
                                   name="search">
                                   <Search
                                       allowClear
                                       onSearch={retrieveSearch} />
                               </Form.Item>
                           </Form>
                           <Table columns={searchCols} 
                               dataSource={searchResult}
                               pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '50']}}
                               />
                           {/* <Button onClick={clearSearch} size='small' style={{whiteSpace: "normal", height: 'auto'}}>Clear Table</Button> */}
                       </div>
                   </Modal>

                   {/*<Text strong style={{marginLeft: 'auto', marginRight: '-10pt'}}>*/}
                   {/*    <span style={{fontWeight: 'normal'}}>Region :</span> {region}*/}
                   {/*</Text>*/}
               </Header>
               <Layout style={{display: 'flex', height: '100%'}}>
                   <Sider width={300}
                          style={{
                              background: colorBgContainer,
                              paddingTop: 10,
                              overflow: 'auto', 
                              marginLeft: '5px'
                          }}>
                            {taxonomy_list.length > 0 
                                ? 
                                taxonomy_list.map((item, i, array) => {
                                    return <div>
                                            <Menu
                                                mode="inline"
                                                // id='rrkMenu'
                                                style={{
                                                    // height: '100%',
                                                    borderRight: 0,
                                                    textTransform: 'capitalize',                                
                                                    whiteSpace: 'normal',
                                                }}                            
                                                onOpenChange={onOpenChange}
                                                openKeys={defaultKeys}
                                                selectedKeys={selectedKeys}
                                                items={item}
                                                onClick={onClick}
                                                forceSubMenuRender={true}
                                            />
                                            {/* <hr style={{marginTop: 10, marginBottom: 10, color: 'lightgrey', borderColor: 'lightgrey'}}/> */}
                                        </div>
                                })
                            // <div>{taxonomy_list.length}</div>
                            : <div></div> 
                            }
                       
                   </Sider>
                   <Layout style={{paddingLeft: '5px', paddingRight:'5px'}}
                   >
                       <Content
                           style={{
                               padding: 24,
                               margin: 0,
                               background: colorBgContainer,
                               overflow: 'auto'
                           }}
                       >
                           {
                               dataset ?
                                   <div>
                                       <Breadcrumb>
                                           <Breadcrumb.Item>{""}</Breadcrumb.Item>
                                               {   
                                               selectedKeys.length > 1 && Array.isArray(selectedKeys)
                                               ?                                          
                                                selectedKeys[1].split('/').map((item, i) =>
                                                        <Breadcrumb.Item key={"category-" + i}>{item === '' ? collection.name : item}</Breadcrumb.Item>
                                                )
                                               : <span>{collection.name}</span>
                                           }
                                       </Breadcrumb>

                                       <div style={{paddingLeft: '5pt'}}>
                                           <Title level={3} style={{textTransform: 'capitalize', padding: '5pt 0'}}>
                                               {dataset.name}
                                           </Title>
                                           <Space style={{marginBottom: 20}}>
                                           {
                                            isNotShapeFile(dataset) ? 
                                                inWorkspace() ?
                                                    <Button type="primary"
                                                            style={{
                                                                backgroundColor: '#D11E00',
                                                                borderColor: "white"
                                                            }}
                                                            onClick={removeFromWorkspace}>
                                                        Remove from Workspace
                                                    </Button>
                                                    :
                                                    <Button type="primary"
                                                            onClick={addToWorkspace}>
                                                        Add to Workspace
                                                    </Button>
                                                : null
                                            }
                                            {
                                                <Button type="primary"
                                                        onClick={() => {
                                                            setLoading(true);
                                                            dataset.data_type == 'shapefile'
                                                            ? download_shape(layerConfig, setLoading)
                                                            : download(layerConfig.gis_services[0].layer_name,
                                                                layerConfig.file_path.includes(".") 
                                                                    ? layerConfig.file_path.split('.').slice(0, -1)
                                                                    : layerConfig.file_path,
                                                                setLoading
                                                            );
                                                        }}
                                                        loading={loading}
                                                >
                                                    Download
                                                </Button>
                                            }
                                            {
                                                loading ?
                                                <span>
                                                    <div colSpan={2}
                                                        style={{padding: '15px 15px 15px 15px'}}>
                                                        <div style={{
                                                            // width: 260,
                                                            borderRadius: '25px',
                                                            border: '1px solid lightgray',
                                                            padding: '15px'
                                                        }}>
                                                            The data packing process typically
                                                            completes within a few seconds, but
                                                            depending on the size of the data
                                                            being downloaded, it may take up
                                                            to 2 minutes in the slowest case.
                                                        </div>
                                                    </div>
                                                </span> : null
                                            }
                                           </Space>
                                           {
                                               dataset && dataset.dataset_metadata ?
                                                   <div>
                                                       <table style={{width: '100%', height: '100%'}}>
                                                           <tbody>
                                                           <tr>
                                                               <td style={{
                                                                   height: 400,
                                                                   width: 500,
                                                                   minWidth: 500,
                                                                   minHeight: 400,
                                                                   position: 'relative',
                                                                   rowspan: 4                                                                   
                                                               }}>
                                                                {dataset.gis_services.length > 0 && dataset.gis_services[0].layer_name !== null
                                                                  ? <div><MapComponent map={map}
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: 0,
                                                                            left: 0,
                                                                            zIndex: 800                                                                                    
                                                                        }}/>
                                                                   <img id="legend"
                                                                        src={legend_url}
                                                                        alt=""
                                                                        style={{
                                                                            left: 10,
                                                                            bottom: 10,
                                                                            position: 'absolute',
                                                                            zIndex: 900
                                                                        }}></img>
                                                                    </div>                                                                    
                                                                    : <Space><span direction="vertical" align={'center'}>Map Not Available</span></Space>}
                                                               </td>
                                                               <td style={{
                                                                   minWidth: 500,
                                                                   padding: '3px 15px',
                                                                   verticalAlign: 'top'
                                                               }}>
                                                                   <table border={0}>
                                                                       <tbody>
                                                                        {metadata.filter(e => e.text_value === null || e.text_value.length < 150).map(e => {                                                                           
                                                                            let value = e.text_value !== null && e.text_value.length > 0
                                                                                        ? e.text_value
                                                                                        : e.float_value  !== null 
                                                                                            ? e.float_value 
                                                                                        : e.int_value                                                                            

                                                                            let label = e.name
                                                                            if(data_dict.length !== 0) {
                                                                                let dict = data_dict.filter(w => w.name == e.name)

                                                                                if(dict !== undefined && dict.length > 0){
                                                                                    label = dict[0].value
                                                                                }
                                                                            } else {
                                                                                label = column_dict[label] ?? label
                                                                            }                                                                            

                                                                            return <tr>
                                                                                <td style={{
                                                                                    whiteSpace: 'nowrap',
                                                                                    verticalAlign: 'top',
                                                                                    width: '30%',
                                                                                    minWidth: 130
                                                                                }}>
                                                                                    <Text strong style={{paddingRight: 8}}>
                                                                                        {label}
                                                                                    </Text>
                                                                                </td>
                                                                                <td>
                                                                                    <Text><span dangerouslySetInnerHTML={{__html: value}}></span></Text>
                                                                                </td>
                                                                            </tr>
                                                                        })}
                                                                        {Object.keys(dataset).filter(e => Array.isArray(dataset[e]) === false
                                                                            && dataset[e] !== null && dataset[e].length < 150).map(e => {       
                                                                                let value = dataset[e]
                                                                                if(new Date(value).toString() !== 'Invalid Date'){                                                                                    
                                                                                    value = new Date(value).toISOString().split('T')[0]
                                                                                }else if(typeof value == 'string') {
                                                                                    value = value.replace(/[^\x00-\x7F]/g, "")                                                                                    
                                                                                }   
                                                                                
                                                                                let label = e
                                                                                if(data_dict.length !== 0) {
                                                                                    let dict = data_dict.filter(w => w.name == e)
                                                                                    if(dict !== undefined && dict.length > 0){
                                                                                        label = dict[0].value
                                                                                    }
                                                                                } else {
                                                                                    label = column_dict[label] ?? label
                                                                                }   

                                                                                return <tr>
                                                                                    <td style={{
                                                                                        whiteSpace: 'nowrap',
                                                                                        verticalAlign: 'top',
                                                                                        width: '30%',
                                                                                        minWidth: 130
                                                                                    }}>
                                                                                        <Text strong style={{paddingRight: 8}}>
                                                                                            {label}
                                                                                        </Text>
                                                                                    </td>
                                                                                    <td>
                                                                                        <Text><span dangerouslySetInnerHTML={{__html: value}}></span></Text>
                                                                                    </td>
                                                                                </tr>
                                                                            })}
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            </tr>
                                                            {metadata.filter(e =>  e.text_value != null && e.text_value.length >= 150)
                                                                .map(e => {                                                                   
                                                                    let value = e.text_value !== null && e.text_value.length > 0
                                                                        ? e.text_value 
                                                                        : e.float_value  !== null 
                                                                            ? e.float_value 
                                                                        : e.int_value

                                                                        if(new Date(value).toString() !== 'Invalid Date'){
                                                                            value = new Date(value).toISOString().split('T')[0]
                                                                        }else if(typeof value == 'string') {
                                                                            console.log('its a value')
                                                                            value = value.replace(/[^\x00-\x7F]/g, "")
                                                                            console.log(value)
                                                                        }     

                                                                    let label = e.name
                                                                    if(data_dict.length !== 0) {
                                                                        let dict = data_dict.filter(w => w.name == e.name)
                                                                        if(dict !== undefined && dict.length > 0){
                                                                            label = dict[0].value
                                                                        }
                                                                    }else {
                                                                        label = column_dict[label] ?? label
                                                                    }   

                                                                    return <tr>
                                                                        <td colSpan={6} style={{
                                                                            verticalAlign: 'top',
                                                                            maxWidth: 500,
                                                                            paddingTop: 15
                                                                        }}>
                                                                            <Text strong style={{paddingRight: 8, fontWeight: 'bold'}}>
                                                                                {label}:
                                                                            </Text>
                                                                            <Text>{value}</Text>
                                                                            {/* <Text><span dangerouslySetInnerHTML={{__html: value}}></span></Text> */}
                                                                        </td>
                                                                    </tr>
                                                                })}
                                                                {Object.keys(dataset).filter(e => Array.isArray(dataset[e]) === false
                                                                        && dataset[e] !== null && dataset[e].length >= 150).map(e => {  
                                                                        let value = dataset[e]
                                                                        if(new Date(value).toString() !== 'Invalid Date'){
                                                                            value = new Date(value).toISOString().split('T')[0]
                                                                        }  

                                                                        let label = column_dict[e] ?? e

                                                                        return <tr>
                                                                            <td colSpan={6} style={{
                                                                            verticalAlign: 'top',
                                                                            maxWidth: 500,
                                                                            paddingTop: 15
                                                                            }}>
                                                                                <Text strong style={{paddingRight: 8, fontWeight: 'bold'}}>
                                                                                {label}:
                                                                            </Text>
                                                                            <Text><span dangerouslySetInnerHTML={{__html: value}}></span></Text>
                                                                            </td>
                                                                        </tr>
                                                                    })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                :
                                                null 
                                            }                                                                       
                                       </div>
                                   </div>
                                : collection.data_collection_id !== undefined
                                ? 
                                   <div>
                                       <Title level={3}>
                                          {collection.name}
                                       </Title>

                                       <div style={{marginTop: 10}}>
                                           <div className="data-info">
                                            <span dangerouslySetInnerHTML={{__html: collection.description}}></span>
                                           </div>
                                       </div>
                                   </div>
                                :
                                <div> 
                                    <Title>Regional Resource Kits</Title>
                                    <h2>Introduction</h2>
                                    <p>
                                        The Agreement for Shared Stewardship (August 2020) between the State
                                        of California and the USDA Forest Service Pacific Southwest Region
                                        emphasizes two key actions by 2025: treat one million acres per year
                                        and develop/implement it via a joint plan. The "million-acre" plan
                                        will prioritize community safety and ecological restoration and it
                                        will be developed in collaboration with all stakeholders.
                                        Significant progress has been made toward preparing for substantive
                                        actions that will realize these ambitious goals. The State of
                                        California has issued a new 
                                        <a target="_blank" href="https://wildfiretaskforce.org/wp-content/uploads/2022/04/californiawildfireandforestresilienceactionplan.pdf">
                                            Wildfire and Forest Resilience Action Plan
                                        </a>

                                        that is designed to strategically accelerate efforts to restore the
                                        health and resilience of California forests through a joint
                                        State-Forest Service framework to enhance stewardship in California.
                                        We have both the social incentives and the scientific knowledge to
                                        pursue meaningful restoration of forested landscapes in California.
                                        An essential component of these initiatives are the data that can a.
                                        enable through and rapid evaluation of landscape and b. support
                                        expansion of restoration projects to a larger spatial scale. Access
                                        to high-resolution remote sensing and GIS data sources, experiences
                                        with landscape restoration assessment and modeling efforts, and
                                        advancements in high-performance computing capabilities now enable
                                        land managers to take steps to work effectively at a landscape scale
                                        throughout California.
                                    </p>
                                    <h2>Basic Principles of Regional Resource Kits</h2>
                                    <p>
                                        The Regional Resource Kits have adopted the “Framework for
                                        Resilience” to provide a structure for assessing landscape
                                        conditions, setting objectives, designing projects, and measuring
                                        progress towards social-ecological resilience. There are ten pillars
                                        that represent the desired outcomes of landscape resilience. Each of
                                        the pillars provide a series of <b>metrics</b>

                                        for assessing landscape conditions and verifying that actions meet
                                        resilience objectives. Metrics describe the characteristics of
                                        elements in quantitative or qualitative terms. Users can use metrics
                                        to assess, plan for, measure, and monitor progress towards desired
                                        outcomes and greater resilience.
                                    </p>
                                    <h2>What are in the Regional Resource Kits?</h2>
                                    <p>
                                        Regional Resource Kits (RRK) are sets of data created to accelerate
                                        the work by regional partners and collaboratives to reduce wildfire
                                        hazard and improve the conditions of forested and shrub landscapes.
                                        The kits include, among other elements, a database of
                                        management-relevant metrics that have been vetted by federal, state,
                                        and academic scientists and spatially explicit assessments of
                                        current conditions for key resources in the region. The Resource Kit
                                        will be revised based on user feedback and updated as new science
                                        and technologies are developed. The data in the kits can be used
                                        with a conventional GIS and/or with specialized Decision Support
                                        Tools created to analyze spatial data. There will be a Regional
                                        Resource Kit for each of the four major regions in California. Each
                                        Kit will include:
                                        <ul>
                                            <li>
                                                A core set of metrics used to quantify each of the 10
                                                Pillars statewide (Tier 1 metrics)
                                            </li>
                                            <li>
                                                An additional set of metrics that are relevant to conditions
                                                specifically within that region (Tier 2 metrics)
                                            </li>
                                            <li>
                                                A package of maps and data that characterize current
                                                conditions and desired conditions within the region
                                            </li>
                                            <li>
                                                A metric dictionary which defines each metric in detail, and
                                                the necessary metadata.
                                            </li>
                                        </ul>
                                    </p>
                                    <h2>How will this information be useful to land managers?</h2>
                                    <ul>
                                        <li>Landscape condition assessment</li>
                                        <li>Landscape planning at a range of scales</li>
                                        <li>Project (on-the-ground) planning, NEPA preparation</li>
                                        <li>
                                            Forest Planning analysis tools to evaluate management
                                            alternatives
                                        </li>
                                        <li>
                                            Quantification of costs and benefits for planned projects to
                                            stimulate funding investments
                                        </li>
                                        <li>Evaluation of restoration progress</li>
                                        <li>Working collaboratively across land ownerships</li>
                                    </ul>
                                    <p>
                                        Ultimately this information is intended to improve forest landscape
                                        conditions to be more resilient into the future:
                                        <ul>
                                            <li>Reduced wildfire risk and threats</li>
                                            <li>Mitigated climate change</li>
                                            <li>Enhanced water security</li>
                                            <li>Enhanced biodiversity conservation</li>
                                        </ul>
                                    </p>
                                </div> 
                           }
                       </Content>
                   </Layout>
               </Layout>
           </Layout>

       </div>
   );
};

export default DataCatalog;


