import React from "react";
import { Typography, Row, Col, Space } from 'antd';
import forest_image from './images/home_forest.jpeg'
import cwi_image from './images/cwi_full_logo.png'
import interagency_treatment from './images/interagency_treatment.png'
import cwi_small_logo from './images/cropped-CWFRTF_logo.png'

const { Title } = Typography;
const base_url = process.env.REACT_APP_API_URL
console.log(base_url)

const Home = () => {

		return (

			<body style={{backgroundColor: "#FDFEF5"}}>
				<div>{base_url}</div>
				{/* <div>{another;/}</div> */}
				<div style={{display: "inline-flex", width: "100%", height: "100%"}}>
					<div style={{textAlign: 'left', padding: 80, backgroundImage: `url(${forest_image})`, backgroundSize: "cover", backgroundPosition: "center center", width: "100%", height: "60%"}}>
						<div style={{display:"flex"}}>
							<div style={{marginRight:100}}>
								<h1 style={{color: '#1F4E11', textAlign: 'left', fontWeight: 300, fontSize: 32}}>Federated wildfire and landscape resilience data all in one place</h1>
								<p style={{color: '#1F4E11', textAlign: 'left'}}>Welcome. Please login by clicking on the top-right person icon and selecting 'Login'.</p>
							</div>
							{/* <img style={{borderRadius:4,boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}} width="350" src="https://i.ibb.co/3FccPFS/frame-generic-light-1.png"></img> */}
							{/* <input style={{backgroundColor: "rgba(255, 255, 255, 0.9)", width: "70%", padding: 10, margin: 10, borderRadius:20, border: "1px solid #7EC06C"}} type="text" placeholder="Search our super cool forest data.."></input>
							<button style={{marginLeft: 16,fontSize: 16,letterSpacing: "-0.1px",fontWeight: 500,textDecoration: "none !important",backgroundColor: "#141516",color: "#fff", border: "none",cursor: "pointer",justifyContent: "center",padding: "12px 36px",height: 48,textAlign: "center",whiteSpace: "nowrap",borderRadius: 25, background: "linear-gradient(65deg, #D1E667 0, #77BD6A 100%)"}} type="submit">Search</button><br/><br/> */}
							{/* <p>[search bar]</p> */}
							{/* <ul style={{color: "#1F4E11",fontWeight: "bold"}}>
								<li>Comprehensive search and filter</li>
								<li>Combine and visualize data on map interface</li>
								<li>Extensive geographical and environmental data</li>
								<li>Organize your user dashboard</li>
							</ul> */}

							{/* <a style={{marginTop: 16,display: "inline-flex",fontSize: 16,letterSpacing: "-0.1px",fontWeight: 500,textDecoration: "none !important",backgroundColor: "#141516",color: "#fff", border: "none",cursor: "pointer",justifyContent: "center",padding: "12px 36px",height: 48,textAlign: "center",whiteSpace: "nowrap",borderRadius: 4, background: "linear-gradient(65deg, #D1E667 0, #77BD6A 100%)"}} href="#">Explore Map link?</a> */}
						</div>
						<div style={{display:"flex", marginTop: 200, height: '100%', padding: 0}}>
						<div style={{width: 325, padding: "16px 10px"}}>
							<div style={{position: 'relative', backgroundColor: "#ffffff", padding: "10px 20px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: 4, border: "1px solid #7EC06C", height: 300}}>
								<a target="_blank" href="https://rrk.sdsc.edu/">
									<div style={{ display: "block", alignItems: "center"}}>
										{/* <div style={{alignItems: "center", marginBottom: 0, marginRight:16}}>
											<img
											height={60}
											src={cwi_image}
											/>
										</div>  */}
										<h4 style={{fontSize: 14, marginTop: 10, fontWeight: "bold"}}> Regional Resource Kits</h4>
										<br></br>
									</div>
								</a>
								<p style={{marginTop: -10,fontSize:13, paddingBottom: 20}}>
								Regional Resource Kits (RRK) include, among other elements, a database of management-relevant metrics that have been vetted by federal, state, and academic scientists and spatially explicit assessments of current conditions for key resources in the region.
								</p>
								<div style={{position: "absolute", bottom: 16, right: 16 }}>
									<img height={20} src={cwi_small_logo}></img>
								</div>		
							</div>							
						</div>

						<div style={{ width: 325, padding: "16px 10px"}}>
								<div style={{position: 'relative', backgroundColor: "#ffffff", padding: "10px 20px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: 4, border: "1px solid #7EC06C", height: 300}}>
									<a target="_blank" href="https://gsal.sig-gis.com/portal/apps/experiencebuilder/experience/?id=95be34ea351b440f9e4ce0f9d3826a77&page=Activity">
										<div style={{ display: "block", alignItems: "center"}}>
											{/* <div style={{alignItems: "center", marginBottom: 10, marginRight:16}}>
												<img
												height={40}												
												src={interagency_treatment}
												style={{backgroundColor: '#1E1A10', borderRadius: '3px'}}
												/> 
											</div> */}
											<h4 style={{ fontSize: 14, marginTop: 10, fontWeight: "bold"}}>California Wildfire and Landscape Interagency Treatment Data</h4><br></br>
										</div>
									</a>
									<p style={{marginTop: -10,fontSize:13, paddingBottom: 20}}>
									Wildfire fuels treatments, timber harvests, and forest resiliency data are drawn from multiple sources, formatted into the California Wildfire & Forest Resilience Task Force template, and enriched with attributes for vegetation, ownership, county, WUI, and Task Force Region.
									</p>
									<div style={{position: "absolute", bottom: 16, right: 16 }}>
										<img height={20} src={cwi_small_logo}></img>
									</div>		
								</div>
							</div>
							<div style={{ width: 325, padding: "16px 10px"}}>
								<div style={{position: 'relative', backgroundColor: "#ffffff", padding: "10px 20px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: 4, border: "1px solid #7EC06C", height: 300}}>
									<a target="_blank" href="http://twsa.ucsd.edu:5000/">
										<div style={{ display: "block", alignItems: "center"}}>
											{/* <div style={{alignItems: "center", marginBottom: 10, marginRight:16}}>
												<img
												height={35}
												src={wifire_image_2} style={{borderRadius: '3px'}}/>
											</div>  */}
											<h4 style={{ marginTop: 10, fontSize: 14, fontWeight: "bold"}}>Data Catalog</h4><br></br>
										</div>
									</a>
									<p style={{marginTop: -10,fontSize:13, paddingBottom: 20 }}>
									CKAN Organizations are used to create, manage and publish collections of datasets. Users can have different roles within an Organization, depending on their level of authorization to create, edit and publish.
									</p>	
									<div style={{position: "absolute", bottom: 16, right: 16}}>
										<img height={20} src={cwi_small_logo}></img>
									</div>								
								</div>
							</div>
							{/* <div style={{ width: 325, padding: "16px 10px"}}>
								<div style={{backgroundColor: "#ffffff", padding: "10px 20px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: 4, border: "1px solid #7EC06C", height: 300}}>
									<a target="_blank" href="http://twsa.ucsd.edu:5000/">
										<div style={{ display: "block", alignItems: "center"}}>
											<div style={{alignItems: "center", marginBottom: 10, marginRight:16}}>
												<img
												height={35}
												src={wifire_image_2} style={{borderRadius: '3px'}}/>
											</div> 
											<h4 style={{marginLeft: 10, fontSize: 14, fontWeight: "bold"}}>Data Catalog</h4><br></br>
										</div>
									</a>
									<p style={{marginTop: -10,fontSize:13}}>
									CKAN Organizations are used to create, manage and publish collections of datasets. Users can have different roles within an Organization, depending on their level of authorization to create, edit and publish.
									</p>
								</div>
							</div> */}
						</div>
					</div>
					{/* <div style={{textAlign: 'left', marginTop: '1em', padding: 0, backgroundColor:"#ffffff", height: "80%"}}>
						<img height="100%" src="https://149835821.v2.pressablecdn.com/wp-content/uploads/2022/11/1-2.png"></img>
					</div> */}
				</div>
			</body>
		);
	}


export default Home;